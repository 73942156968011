import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const IconWrapper = styled('div')(({ theme }) => ({
  margin: '0 3rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    margin: '0 2rem',
  },

  '& > div': {
    margin: '0 auto 1.5rem',
    width: '4.5rem',

    [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
      marginBottom: '0.9rem',
      svg: {
        transform: 'scale(0.667)',
      },
    },

    svg: {
      width: '100%',
    },
  },

  '& > span': {
    display: 'block',

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
    },
  },
}));

export default IconWrapper;
