function Skip() {
  return (
    <svg
      height="30"
      viewBox="0 0 45 30"
      width="45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="#43A7FB" strokeWidth="3">
        <path d="M42.19 13.51L23.644 2.166c-.7-.428-1.656.015-1.656.766v10.444L3.657 2.167C2.956 1.74 2 2.182 2 2.933v22.685c0 .751.957 1.194 1.656.766l18.331-11.21v10.444c0 .751.956 1.194 1.656.766L42.191 15.04c.606-.37.606-1.161 0-1.532z" />
        <path d="M42.645 2v25.915" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export default Skip;
