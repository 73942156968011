import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Hero = styled('div')(({ theme }) => ({
  height: '28.5rem',
  overflow: 'hidden',
  position: 'relative',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    height: '22rem',
  },
}));

export default Hero;
