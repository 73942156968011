import Hero from './primitives/Hero';
import Image from './primitives/Image';
import Mask from './primitives/Mask';
import { hero } from 'constants/assets';

function PromoHero() {
  return (
    <Hero>
      <Image alt="Promo Hero" src={hero} />
      <Mask />
    </Hero>
  );
}

export default PromoHero;
