import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Header = styled('h4')(({ theme }) => ({
  color: theme.colors.gray.dark,
  fontSize: theme.fonts.size.medium,
  fontWeight: 'bold',
  margin: '0 0 3rem 3rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: theme.fonts.size.small,
  },
}));

export default Header;
