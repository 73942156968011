import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Header = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  margin: '-18rem auto 5rem',
  maxWidth: '92rem',
  position: 'relative',
  zIndex: 2,

  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    marginLeft: '-3rem',
    marginTop: '-13rem',
    maxWidth: 'none',
  },

  '@media (max-width: 950px)': {
    marginTop: '-11rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginTop: '-13.5rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    marginLeft: 'auto',
    marginTop: '-13rem',
  },
}));

export default Header;
