import IconsWrapper from './primitives/IconsWrapper';
import IconWrapper from './primitives/IconWrapper';
import Offline from './Offline';
import Skip from './Skip';
import Songs from './Songs';

function PromoIcons() {
  return (
    <IconsWrapper>
      <IconWrapper>
        <div>
          <Skip />
        </div>
        <span>Unlimited</span>
        <span>Skips</span>
      </IconWrapper>
      <IconWrapper>
        <div>
          <Songs />
        </div>
        <span>Millions of</span>
        <span>Songs</span>
      </IconWrapper>
      <IconWrapper>
        <div>
          <Offline />
        </div>
        <span>Offline</span>
        <span>Listening</span>
      </IconWrapper>
    </IconsWrapper>
  );
}

export default PromoIcons;
