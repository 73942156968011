import PromoCards from './PromoCards';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCards } from 'state/Promo/selectors';
import type { Cards } from 'state/Promo/types';
import type { State } from 'state/types';

export default connect(
  createStructuredSelector<State, { cards: Cards }>({ cards: getCards }),
)(PromoCards);
