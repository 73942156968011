import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const Link = styled(NavLink)(({ theme }) => ({
  backgroundColor: theme.colors.blue.primary,
  borderRadius: '0.4rem',
  color: theme.colors.white.primary,
  display: 'inline-block',
  fontSize: theme.fonts.size.small,
  margin: '3rem 0',
  padding: '1.2rem 8.3rem',

  '&:hover': {
    backgroundColor: theme.colors.blue.secondary,
    textDecoration: 'none',
  },
}));

export default Link;
