import Footer from 'components/Footer';
import GenericHead from 'components/GenericHead';
import PromoCards from './PromoCards';
import PromoHeader from './PromoHeader';
import PromoHero from './PromoHero';
import useMount from 'hooks/useMount';
import usePageView from 'modules/Analytics/usePageView';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getSource } from 'state/Entitlements/selectors';
import { Helmet } from 'react-helmet';
import { setHasHero } from 'state/Hero/actions';
import { useDispatch, useSelector } from 'react-redux';

function Promo() {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const userSubscriptionSorce = useSelector(getSource);

  usePageView({ pageName: 'promo' });

  useMount(() => {
    dispatch(setHasHero(true));
    return () => dispatch(setHasHero(false));
  });

  const keywords =
    'All Access, Plus, Plans, Subscriptions, Discount, Codes, Coupons, Special Offers';

  const title = translate(
    'iHeart Codes Coupons and Special Offers for Unlimited Music',
  );

  return (
    <>
      <GenericHead
        deeplink="goto/offers"
        description={translate(
          'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!',
        )}
        title={title}
      />
      <Helmet
        meta={[
          { content: title, itemProp: 'name' },
          { content: `${keywords}`, name: 'keywords' },
        ]}
      />
      <PromoHero />
      <PromoHeader />
      <PromoCards source={userSubscriptionSorce} />
      <Footer />
    </>
  );
}

export default Promo;
