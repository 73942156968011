import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Content = styled('div')(({ theme }) => ({
  margin: 'calc(5rem - 1vw) auto 0',
  padding: '0 1.5rem',
  textAlign: 'center',
  width: '46%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    margin: '-1vw auto 0',
    padding: '0 3rem 0 0',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    maxWidth: '34rem',
    padding: '0 3rem',
    width: '100%',
  },
}));

export default Content;
