import styled from '@emotion/styled';

const Mask = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['100'],
  bottom: 0,
  height: '10rem',
  position: 'absolute',
  transform: 'skewX(91.25deg)',
  transformOrigin: '100% 100%',
  width: '100%',
  zIndex: 1,
}));

export default Mask;
