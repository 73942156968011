import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Cards = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'left',
  padding: '0 1.5rem',
  textAlign: 'center',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    padding: '0 0.75rem',
  },
}));

export default Cards;
