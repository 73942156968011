import styled from '@emotion/styled';

const ContentHeader = styled('h4')(({ theme }) => ({
  color: theme.colors.white.primary,
  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontSize: '2.4rem',
  fontWeight: 300,
  lineHeight: '3.5rem',

  '@media (max-width: 950px)': {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
}));

export default ContentHeader;
