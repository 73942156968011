import ImagePrimitive from 'components/Image';
import styled from '@emotion/styled';

const Image = styled(ImagePrimitive)({
  height: '100%',

  '& > img': {
    height: 'auto',
    left: '50%',
    maxWidth: 'none',
    minWidth: '100%',
    position: 'absolute',
    top: 0,
    transform: 'translateX(-50%)',
    width: 'auto',
  },
});

export default Image;
