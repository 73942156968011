import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Card = styled('div')(({ theme }) => ({
  padding: '0 1.5rem',
  width: '33.3333%',
  marginBottom: '6rem',
  height: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    padding: '0 0.75rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    marginBottom: '9rem',
    width: '100%',

    '&:last-child': {
      marginBottom: '2rem',
    },
  },
}));

export default Card;
