import Content from './primitives/Content';
import ContentHeader from './primitives/ContentHeader';
import Header from './primitives/Header';
import Image from 'components/Image';
import ImageContainer from './primitives/ImageContainer';
import Link from './primitives/Link';
import PromoIcons from 'views/Promo/PromoIcons';
import { devices } from 'constants/assets';

type Props = {
  onDemandLink: string;
};

function PromoHeader({ onDemandLink }: Props) {
  return (
    <Header>
      <ImageContainer>
        <Image alt="Promo Hero" aspectRatio={1.54} src={devices} />
      </ImageContainer>
      <Content>
        <ContentHeader>
          Introducing unlimited music on demand with iHeart Plus and All Access
        </ContentHeader>
        <Link target="_blank" to={onDemandLink}>
          Learn More
        </Link>
        <PromoIcons />
      </Content>
    </Header>
  );
}

export default PromoHeader;
