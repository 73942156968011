import styled from '@emotion/styled';

const Link = styled('a')(({ theme }) => ({
  backgroundColor: theme.colors.blue.primary,
  borderRadius: '0.4rem',
  color: theme.colors.white.primary,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: theme.fonts.size.small,
  padding: '0.9rem 2.6rem',
  position: 'relative',
  transform: 'translateY(-50%)',
  zIndex: 1,

  '&:hover': {
    backgroundColor: theme.colors.blue.secondary,
    textDecoration: 'none',
  },
}));

export default Link;
