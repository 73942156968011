function Songs() {
  return (
    <svg
      height="47"
      viewBox="0 0 44 47"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#43A7FB"
        strokeLinecap="round"
        strokeWidth="3"
      >
        <path d="M17.185 38c0 4.141-3.399 7.5-7.593 7.5C5.4 45.5 2 42.141 2 38s3.4-7.5 7.592-7.5c4.194 0 7.593 3.359 7.593 7.5zM17.185 11v27M41.481 30.5c0 4.141-3.399 7.5-7.593 7.5-4.193 0-7.592-3.359-7.592-7.5s3.4-7.5 7.592-7.5c4.194 0 7.593 3.359 7.593 7.5zM41.741 2.342v27.345M17.185 11l24.296-7.5" />
      </g>
    </svg>
  );
}

export default Songs;
