function Offline() {
  return (
    <svg
      height="46"
      viewBox="0 0 46 46"
      width="46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="#43A7FB">
        <path
          d="M44 23c0 11.598-9.402 21-21 21S2 34.598 2 23 11.402 2 23 2s21 9.402 21 21z"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <path d="M14 24.5l9.025 7.5L32 24.542" strokeWidth="2.571" />
        <path d="M23 14v18.666" strokeLinecap="round" strokeWidth="2.571" />
      </g>
    </svg>
  );
}

export default Offline;
