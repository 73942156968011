import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ImageContainer = styled('div')(({ theme }) => ({
  width: '54%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    display: 'none',
  },
}));

export default ImageContainer;
